import { f7 } from 'framework7-react';
import parse from 'html-react-parser';
import { SetStateAction, useMemo } from 'react';
import style from './TradeGuaranteeRenderStatusStyle.module.css';
import CheckIcon from '@/assets/images/reservation-status/status11.svg';
import StatusInspectionReviewCompleted from '@/assets/images/reservation-status/status12.svg';
import StatusRefundsProcessed from '@/assets/images/reservation-status/status14.svg';
import StatusNotPass from '@/assets/images/reservation-status/status15.svg';
import StatusInspectionUnderway from '@/assets/images/reservation-status/status5.svg';
import StatusUnderWarranty from '@/assets/images/reservation-status/status6.svg';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import {
  SCREENING_RESULT_TYPE,
  STATUS_TRADE_GUARANTEE,
} from '@/consts/warranty';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeRenderStatusProps {
  data?: GetDealWarrantyInfoApiResponse['data'];
  setOpenRegisterBankAccount: (value: SetStateAction<boolean>) => void;
}

export const TradeGuaranteeRenderStatus = ({
  data,
  setOpenRegisterBankAccount,
}: TradeGuaranteeRenderStatusProps) => {
  const valueStatus = useMemo(() => {
    switch (data?.status) {
      case STATUS_TRADE_GUARANTEE.UNDER_REVIEW:
        return {
          image: StatusInspectionUnderway,
          title: '審査中',
          content: `ただいま、審査中でございます。<br />
          審査結果が出次第ご連絡いたしますので、<br />
          いましばらくお待ちください。`,
        };
      case STATUS_TRADE_GUARANTEE.IN_PROGRESS:
        return {
          image: StatusInspectionUnderway,
          title: '確認中',
          content: `ただいま申請内容と必要書類を確認中です。<br />
          いましばらくお待ちください。`,
        };
      case STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT:
        return {
          image: StatusInspectionReviewCompleted,
          title: '審査が完了いたしました',
          content: `お待たせいたしました。<br />
          審査が完了いたしました。<br />
          詳細は以下をご確認ください。`,
        };
      case STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED:
        if (
          data.deal_warranty_screening_result_type ===
          SCREENING_RESULT_TYPE.OUT_OF_SCOPE
        ) {
          return {
            image: StatusNotPass,
            title: '審査に通過できませんでした',
            content: `お待たせいたしました。<br />
              審査が完了いたしました。 <br />
              取引保証プラン適用対象外となります。 <br />
              詳細は以下をご確認ください。`,
          };
        }
        return {
          image: StatusInspectionReviewCompleted,
          title: '審査が完了いたしました',
          content: `お待たせいたしました。<br />
          審査が完了いたしました。<br />
          詳細は以下をご確認ください。`,
        };
      case STATUS_TRADE_GUARANTEE.CONTINUE_USE_AFTER_REVIEW:
        return {
          image: StatusUnderWarranty,
          title: '審査が完了いたしました',
          content: `審査結果につきまして、下記をご確認ください。​`,
        };
      case STATUS_TRADE_GUARANTEE.COMPLETED:
        return {
          image: CheckIcon,
          title: '対応完了',
          content: `すべての対応が完了いたしました。`,
        };
      case STATUS_TRADE_GUARANTEE.REFUNDS_PROCESSED:
        return {
          image: StatusRefundsProcessed,
          title: '返車・返金の対応中です',
          content: ``,
        };
      default:
        return {};
    }
  }, [data?.deal_warranties, data?.status]);

  return (
    <RoundSheet radius="16px">
      <div className={style['trade-guarantee-render-status']}>
        <img src={valueStatus?.image} alt={valueStatus.title} />
        <h3 className={style['status-title']}>{valueStatus?.title}</h3>
        <p className={style['status-content']}>
          {parse(valueStatus?.content || '')}
        </p>

        {/* status under warranty */}
        {data?.status === STATUS_TRADE_GUARANTEE.CONTINUE_USE_AFTER_REVIEW &&
          (data?.deal_warranty_screening_result_type ===
            SCREENING_RESULT_TYPE.MAINTENANCE_DELIVERY ||
            data?.deal_warranty_screening_result_type ===
              SCREENING_RESULT_TYPE.MAINTENANCE_DELIVERY_AND_ACCESSORIES_DELIVERY) && (
            <>
              <br />
              <br />
              修理の進捗につきましては、メンテナンスの画面 <br />
              から確認することができます。
              <Button
                onClick={() => f7.views.main.router.navigate(paths.maintenance)}
                className={style['button-detail']}
                text="メンテナンス画面へ"
              />
            </>
          )}
        {/* status refunds processed */}

        {data?.status === STATUS_TRADE_GUARANTEE.REFUNDS_PROCESSED &&
          !data?.has_refund_account && (
            <>
              <br />
              <br />
              返金用の銀行口座のご登録をお願いいたします。
              <Button
                className={style['button-detail']}
                text="銀行口座を登録する"
                onClick={() => setOpenRegisterBankAccount(true)}
              />
            </>
          )}
      </div>
    </RoundSheet>
  );
};
