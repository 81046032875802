import { FC, useState } from 'react';
import { PopupUploadDocument } from './PopupUploadDocument';
import style from './TradeGuaranteePlanStyle.module.scss';
import Deal1Img from '@/assets/images/warranty/deal1.png';
import { Button } from '@/components/uiParts/Button';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { isIPhoneApp } from '@/config/device';
type TradeGuaranteePlanProps = {};

export const TradeGuaranteePlan: FC<TradeGuaranteePlanProps> = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  return (
    <PageTemplateV2
      pageName="TradeGuaranteePlan"
      title="取引保証プランのご案内"
      showNavBack
    >
      <div className={isIPhoneApp ? style['container'] : ''}>
        <div className={style['wrapper']}>
          <div className={style['header']}>
            取引保証プラン有効化に必要な情報があります
          </div>
          <div className={style['desc']}>
            この度は車両のご購入誠にありがとうございます。
            <br />
            お客様との取引を安全に行うため、取引保証プランを付帯しております。
            <br />
            万が一トラブル発生した場合、なるべくスムーズな審査を行得るようにするため、審査に必要な書類を
            <span>納車後できるだけ早めに</span>登録を行なってください。
          </div>
          <div className={style['block']}>
            <div className={style['block-header-wrapper']}>
              <div className="display-flex flex-row-center-center">
                <div className={style['block-header']}>
                  納車日から1ヶ月の間に発生した
                  <br />
                  トラブルを総額保証いたします！
                </div>
              </div>
              <div className={style['header']}>取引保証プランとは？</div>
            </div>
            <div className={style['image-wrapper']}>
              <img src={Deal1Img} width="50%" alt="deal" />
            </div>
            <div>
              <div className={style['desc']}>
                中古車購入における <span>総額保証</span>
              </div>
              当社とお客様の間で交わしましたご契約内容に虚偽がないことを立証する保証です。
              <br />
              契約トラブルの解決を第三者の立場から行うことで、安全安心な商取引が行われるようになります。
            </div>
          </div>
          <div className={style['remark']}>
            <span>※</span>保証の適用時には審査を行います。
          </div>
        </div>
        <div className={style['button']}>
          <Button onClick={() => setIsPopupOpen(true)}>
            各種書類をアップロードする
          </Button>
        </div>
      </div>
      <PopupUploadDocument
        opened={isPopupOpen}
        onPopupClosed={() => setIsPopupOpen(false)}
      />
    </PageTemplateV2>
  );
};
