import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Checkbox } from './Checkbox';
import style from './PreStartConfirmation.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { handleClickLink } from '@/utils/utils';

interface FormInput {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  fifth: boolean;
}

const validationSchema = yup.object().shape({
  first: yup.boolean().oneOf([true]),
  second: yup.boolean().oneOf([true]),
  third: yup.boolean().oneOf([true]),
  fourth: yup.boolean().oneOf([true]),
  fifth: yup.boolean().oneOf([true]),
});

interface PreStartConfirmProps {
  isConfirmed?: boolean;
  onSubmit: () => void;
}

export const PreStartConfirm: FC<PreStartConfirmProps> = ({
  isConfirmed = false,
  onSubmit,
}) => {
  const formMethods = useForm<FormInput>({
    defaultValues: {
      first: isConfirmed,
      second: isConfirmed,
      third: isConfirmed,
      fourth: isConfirmed,
      fifth: isConfirmed,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = formMethods;

  const totalItems = Object.keys(getValues());
  type KeyForm = keyof typeof getValues;
  const remainingItems = totalItems.filter(
    (key) => !getValues()[key as KeyForm],
  );

  return (
    <div className={style['check-notes']}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={style['check-notes-head']}>注意事項ご確認のお願い</h2>

          <h4 className={style['input-label']}>
            取引保証プランのご注意事項
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h4>
          <p className={style['check-notes-content']}>
            当サービスのお申込みおよびご利用開始にあたりまして、
            <span onClick={() => handleClickLink(paths.tradeAssuranceTerms)}>
              取引保証プラン利用規約
            </span>
            より重要な項目を抜粋しております。
            <br />
            すべての項目をご確認いただき、チェックを入れてください。
          </p>

          <h4 className={style['check-notes-title']}>【取引保証】規約抜粋</h4>
          <Checkbox name="first" isBoolean value={true}>
            本サービスのご加入に伴いまして、「取引保証規約」を必ずご確認ください。​
          </Checkbox>
          <Checkbox name="second" isBoolean value={true}>
            本サービスは、ユーザーが購入した中古車（以下、「対象車両」）に関して、契約内容が履行されなかった場合に、ユーザーに対して補償を提供するものです。​
          </Checkbox>
          <Checkbox name="third" isBoolean value={true}>
            保証の範囲は、取引保証規約第3条に準じます。
          </Checkbox>
          <Checkbox name="fourth" isBoolean value={true}>
            保証適用の申請時には、御契約内容がわかる契約書等の書類をご提出いただきます。​書類が揃っていない場合、または書類が不明瞭である場合、本サービスの保証は適用されないことがあります。​
          </Checkbox>
          <Checkbox name="fifth" isBoolean value={true}>
            本サービスの保証期間は、購入車両の納車日もしくは整備納車日から３０日間です。​
          </Checkbox>
          <div className={style['check-notes-bottom']}>
            <div className={style['check-notes-total']}>
              確認する項目：残り
              <span>{remainingItems.length}件</span>
              (全{totalItems.length}件)
            </div>
            <Button
              type="submit"
              disabled={!isValid}
              className={style['check-notes-button']}
              text="次へ"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
