import { f7 } from 'framework7-react';
import { FC, Fragment, useState } from 'react';
import { ContactPopup } from '../Partials/ContactPopup/ContactPopup';
import { PopupConfirm } from '../PopupConfirm/PopupConfirm';
import style from './TradeGuaranteeExamResultStyle.module.css';
import CheckIcon from '@/assets/images/reservation-status/status11.svg';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import {
  DEAL_WARRANTY_RESULT,
  ISSUE_CATEGORIES,
  SCREENING_RESULT_TYPE,
  STATUS_TRADE_GUARANTEE,
} from '@/consts/warranty';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeExamResultProps {
  data?: GetDealWarrantyInfoApiResponse['data'];
  onClickReturnVehicle: () => void;
  openPopupConfirm: boolean;
  setOpenPopupConfirm: (value: boolean) => void;
}

export const TradeGuaranteeExamResult: FC<TradeGuaranteeExamResultProps> = ({
  data,
  onClickReturnVehicle,
  openPopupConfirm,
  setOpenPopupConfirm,
}) => {
  const [isContactPopupOpened, setIsContactPopupOpened] = useState(false);

  return (
    <>
      <div className={style['trade-guarantee-exam-result']}>
        <RoundSheet radius="16px">
          <div className={style['trade-guarantee-result']}>
            <h3 className={style['trade-guarantee-result-title']}>
              <img src={CheckIcon} alt="Check" />
              審査結果
            </h3>
            {/* header-red || header-blue */}
            {(data?.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED ||
              data?.status ===
                STATUS_TRADE_GUARANTEE.CONTINUE_USE_AFTER_REVIEW ||
              data?.status === STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT ||
              data?.status === STATUS_TRADE_GUARANTEE.COMPLETED) && (
              <ContinueUseAfterReview
                data={data}
                onClickReturnVehicle={onClickReturnVehicle}
                openPopupConfirm={openPopupConfirm}
                setOpenPopupConfirm={setOpenPopupConfirm}
              />
            )}
            {/* {data?.status === STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT && (
              <IncompleteDocument data={data} />
            )} */}
            <p className={style['trade-guarantee-result-note']}>
              ご不明なことがございましたら、
              <span onClick={() => setIsContactPopupOpened(true)}>
                サポートセンター
              </span>
              までお問い合わせください。
            </p>
            <ContactPopup
              opened={isContactPopupOpened}
              handleClose={() => setIsContactPopupOpened(false)}
            />
          </div>
        </RoundSheet>
      </div>
    </>
  );
};

interface ContinueUseAfterReviewProps {
  data: GetDealWarrantyInfoApiResponse['data'];
  onClickReturnVehicle: () => void;
  openPopupConfirm: boolean;
  setOpenPopupConfirm: (value: boolean) => void;
}

const ContinueUseAfterReview: FC<ContinueUseAfterReviewProps> = ({
  data,
  onClickReturnVehicle,
  openPopupConfirm,
  setOpenPopupConfirm,
}) => {
  const handleRedirectTradeReservation = () => {
    f7.view.main.router.navigate(paths.tradeReservation, {
      props: {
        repairId: data?.repair_id,
        repairReceptId: data?.repair_recept_id,
      },
    });
  };
  const groupedDealWarranties = data.deal_warranties.reduce(
    (acc, deal) => {
      if (!acc[deal.issue_category]) {
        acc[deal.issue_category] = {
          name: deal.issue_category_name,
          passed: [],
          rejected: [],
        };
      }
      if (
        data.status === STATUS_TRADE_GUARANTEE.IN_PROGRESS ||
        data.status === STATUS_TRADE_GUARANTEE.UNDER_REVIEW ||
        data.status === STATUS_TRADE_GUARANTEE.REFUNDS_PROCESSED ||
        data.status === STATUS_TRADE_GUARANTEE.COMPLETED ||
        (data.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED &&
          data.deal_warranty_screening_result_type ===
            SCREENING_RESULT_TYPE.RETURN_CAR_SUPPORT)
      ) {
        return acc;
      }
      if (deal.result === DEAL_WARRANTY_RESULT.PASSED) {
        acc[deal.issue_category].passed.push(deal);
      } else {
        acc[deal.issue_category].rejected.push(deal);
      }
      return acc;
    },
    {} as Record<
      number,
      {
        name: string;
        passed: GetDealWarrantyInfoApiResponse['data']['deal_warranties'];
        rejected: GetDealWarrantyInfoApiResponse['data']['deal_warranties'];
      }
    >,
  );

  return (
    <>
      <PopupConfirm
        title="購入した車両の返車手続きを行いますか？"
        handleSubmit={onClickReturnVehicle}
        textButtonSubmit={'返車手続きを行う'}
        opened={openPopupConfirm}
        handleClose={() => setOpenPopupConfirm(false)}
      />
      {data?.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED &&
      data.deal_warranty_screening_result_type ===
        SCREENING_RESULT_TYPE.RETURN_CAR_SUPPORT ? (
        <>
          <p className={style['content']}>
            ご契約内容と車輛状態に相違があることを確認いたしました。つきましては、ご返車の対応とさせていただければと存じます。
          </p>
          <Button onClick={() => setOpenPopupConfirm(true)}>
            購入した車両を返車する
          </Button>
        </>
      ) : (
        <>
          {Object.entries(groupedDealWarranties).map(([key, deals], index) => (
            <div className={style['item-group']} key={key}>
              <h4 className={style['body-damage-title']}>
                {deals.name}について
              </h4>
              {data.deal_warranty_screening_result_type ===
                SCREENING_RESULT_TYPE.RETURN_CAR_SUPPORT &&
                data?.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED && (
                  <Button onClick={onClickReturnVehicle}>
                    購入した車両を返車する
                  </Button>
                )}
              {deals.passed.length > 0 && (
                <>
                  <h3
                    className={`${style['result-header']} ${style['header-blue']}`}
                  >
                    保証対象です
                  </h3>
                  {[
                    ISSUE_CATEGORIES.BODY_DAMAGE,
                    ISSUE_CATEGORIES.EQUIPMENT_INSTALLATION,
                  ].includes(Number(key)) && (
                    <p className={style['trade-guarantee-result-content']}>
                      保証対象内で車両の修理を行います。
                      <br />
                      修理工場へ弊社より損傷の申し送りを行います。
                    </p>
                  )}

                  {[ISSUE_CATEGORIES.ACCESSORY_DEFICIENCY].includes(
                    Number(key),
                  ) && (
                    <p className={style['trade-guarantee-result-content']}>
                      販売店様と厳正な審査を行ったところ、契約の付属品が正しくお届けできていないことが判明しました。
                      <br />
                      後日、ご登録のご住所に付属品をお送りいたします。
                    </p>
                  )}

                  {[ISSUE_CATEGORIES.VEHICLE_SPECIFICATION].includes(
                    Number(key),
                  ) && (
                    /**TODO: TBD　１：車両諸元など提示情報不備、*/
                    <p className={style['trade-guarantee-result-content']}>
                      <></>
                    </p>
                  )}

                  <div className={style['not-covered']}>
                    <h4>トラブル内容</h4>
                    <p className={style['not-covered-subtitle']}>
                      {deals.passed.length > 1 ? (
                        <ol>
                          {deals.passed.map((deal) => (
                            <li key={deal.deal_warranty_id}>
                              {deal.issue_detail}
                            </li>
                          ))}
                        </ol>
                      ) : (
                        deals.passed[0].issue_detail
                      )}
                    </p>
                  </div>
                </>
              )}
              {deals.rejected.length > 0 && (
                <>
                  <h3
                    className={`${style['result-header']} ${style['header-red']}`}
                  >
                    {data.status === STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT
                      ? '申請内容に不備がありました。'
                      : '保証対象外です'}
                  </h3>

                  {data.status ===
                  STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT ? (
                    <p className={style['trade-guarantee-result-content']}>
                      申請していただいた書類に不備・不足情報がありました。
                    </p>
                  ) : (
                    <>
                      <p className={style['trade-guarantee-result-content']}>
                        ご申請いただきましたご内容につきまして、ご対応できかねるものでございます。理由は、以下の通りです。
                      </p>
                    </>
                  )}

                  <>
                    {deals.rejected.map((deal) => (
                      <div
                        className={`${style['not-covered']} ${style['not-covered-group']}`}
                        key={deal.deal_warranty_id}
                      >
                        <div>
                          <h4>トラブル内容</h4>
                          <p className={style['not-covered-subtitle']}>
                            {deal.issue_detail}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                </>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

interface IncompleteDocumentProps {
  data: GetDealWarrantyInfoApiResponse['data'];
}

const IncompleteDocument: FC<IncompleteDocumentProps> = ({ data }) => {
  return (
    <>
      <div className={style['item-group']}>
        <h3 className={`${style['result-header']} ${style['header-red']}`}>
          申請内容に不備がありました
        </h3>
        <div className={style['deal-status']}>
          <p className={style['content']}>
            申請していただいた書類に不備・不足情報がありました。
            <br />
            以下の書類を再アップロードし、再申請を行っててください。
          </p>
          <div className={style['status-remark']}>{data.status_remark}</div>
        </div>
      </div>
      <Button
        onClick={() => {
          f7.views.main.router.navigate(paths.tradeGuaranteeUpdate, {
            props: {
              data: data,
            },
          });
        }}
      >
        再申請を行う
      </Button>
    </>
  );
};
