import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import {
  FieldArrayWithId,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import style from './TradeGuaranteeForm.module.css';
import { ReactComponent as PlusIcon } from '@/assets/images/menu/icon-plus-circle-2.svg';
import { Button } from '@/components/uiParts/Button';
import { Label } from '@/components/uiParts/Label/Label';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import { RhfTextarea } from '@/components/uiParts/ReactHookForm/RhfTextarea/RhfTextarea';
import { SelectCalendar } from '@/components/uiParts/SelectCalendar/SelectCalendar';
import { ISSUE_CATEGORIES } from '@/consts/warranty';

export interface TradeGuaranteeFormInput {
  issues: {
    issue_category: number | null;
    issue_detail: string;
    issue_found_date: string;
  }[];
}

const validationSchema = yup.object().shape({
  issues: yup.array().of(
    yup.object().shape({
      issue_category: yup.number().required(),
      issue_detail: yup.string().required(),
      issue_found_date: yup.string().required(),
    }),
  ),
});

export const ProblemsType = [
  { key: ISSUE_CATEGORIES.BODY_DAMAGE, value: 'ボディ損傷' },
  {
    key: ISSUE_CATEGORIES.VEHICLE_SPECIFICATION,
    value: '車両諸元など提示情報の不備',
  },
  { key: ISSUE_CATEGORIES.EQUIPMENT_INSTALLATION, value: '装備品取り付け不備' },
  { key: ISSUE_CATEGORIES.ACCESSORY_DEFICIENCY, value: '付属品不備' },
];

interface TradeGuaranteeFormProps {
  defaultValues: TradeGuaranteeFormInput;
  onSubmit: (data: TradeGuaranteeFormInput) => void;
  handleBack: () => void;
}

export const TradeGuaranteeForm: FC<TradeGuaranteeFormProps> = ({
  defaultValues,
  onSubmit,
  handleBack,
}) => {
  const formMethods = useForm<TradeGuaranteeFormInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const { fields, append } = useFieldArray<TradeGuaranteeFormInput>({
    control,
    name: 'issues',
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style['trade-guarantee-wrapper']}>
          <h3 className={style['trade-guarantee-head']}>トラブルの詳細</h3>

          {fields.map((field, index) => (
            <IssuesItem
              field={field}
              index={index}
              key={field.id}
              isLastIssue={index === fields.length - 1}
            />
          ))}

          <Button
            className={style['add-item-buttom']}
            outline
            onClick={() => {
              append({
                issue_category: null,
                issue_detail: '',
                issue_found_date: '',
              });
            }}
          >
            <PlusIcon />
            他のトラブルを追加する
          </Button>
        </div>

        <div className={style['trade-guarantee-bottom']}>
          {/* <Button
            outline
            className={style['trade-guarantee-button']}
            text="申請内容を保存"
            onClick={handleBack}
          /> */}

          <Button
            type="submit"
            disabled={!isValid}
            className={style['trade-guarantee-button']}
            text="次へ"
          />
        </div>
      </form>
    </FormProvider>
  );
};

const IssuesItem: FC<{
  field: FieldArrayWithId<TradeGuaranteeFormInput, 'issues', 'id'>;
  index: number;
  isLastIssue: boolean;
}> = ({ field, index, isLastIssue }) => {
  const [date, setDate] = useState(
    field.issue_found_date ? new Date(field.issue_found_date) : new Date(),
  );

  const { control, setValue, trigger } = useFormContext();

  const watchIssues: TradeGuaranteeFormInput['issues'] = useWatch({
    control,
    name: 'issues',
  });

  const options = ProblemsType;

  useEffect(() => {
    setValue(
      `issues.${index}.issue_found_date`,
      format(new Date(date), 'yyyy-MM-dd'),
    );
    trigger();
  }, [date, index, setValue, trigger]);

  return (
    <div
      className={`${style['trade-guarantee-form-item-wrapper']} ${
        isLastIssue ? style['no-border'] : ''
      }`}
    >
      <div className={style['trade-guarantee-form-item']}>
        <div className={style['label-wrapper']}>
          <p className={style['label']}>トラブルの種類</p>
          <Label required />
        </div>

        <div className={style['select-wrapper']}>
          <RhfDropdown
            required
            className={`${style['select']} ${style['input']}`}
            name={`issues.${index}.issue_category`}
          >
            {options.map((item) => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </RhfDropdown>
        </div>
      </div>

      <div className={style['trade-guarantee-form-item']}>
        <div className={style['label-wrapper']}>
          <p className={style['label']}>どのようなトラブルが発生しましたか？</p>
          <Label required />
        </div>

        <RhfTextarea
          name={`issues.${index}.issue_detail`}
          rows={4}
          required
          className={`${style['input']}`}
        />
        <p className={style['note']}>できる限り具体的にご記載ください</p>
      </div>

      <div className={style['trade-guarantee-form-item']}>
        <div className={style['label-wrapper']}>
          <p className={style['label']}>発生日</p>
          <Label required />
        </div>

        <div className={style['input-text']}>
          <SelectCalendar
            name={`issues.${index}.issue_found_date`}
            value={date}
            onChange={setDate}
            maxDate={new Date()}
          />
        </div>
        <p className={style['note']}>
          ※不明の場合はおおよその日付で問題ありません
        </p>
      </div>
    </div>
  );
};
