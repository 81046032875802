import { Icon } from 'framework7-react';
import { FC, useMemo } from 'react';
import style from './UploadDocuments.module.css';
import { ReactComponent as IconCheckGreen } from '@/assets/images/menu/icon-check-green.svg';
import { ReactComponent as IconCheckGrey } from '@/assets/images/menu/icon-check-grey.svg';
import { Button } from '@/components/uiParts/Button';
import {
  Documents,
  GetDealWarrantyInfoApiResponse,
  WarrantyDocument,
} from '@/types/api/warrantyApi';

interface UploadDocumentsProps {
  data: GetDealWarrantyInfoApiResponse['data'];
  uploadDocument: Record<number, Documents>;
  warrantyDocument: WarrantyDocument[];
  handleClickDocument: (document: WarrantyDocument) => void;
  onSubmit: () => void;
}

export const UploadDocuments: FC<UploadDocumentsProps> = ({
  data,
  warrantyDocument,
  uploadDocument,
  handleClickDocument,
  onSubmit,
}) => {
  const uploadedDocs = useMemo(
    () =>
      warrantyDocument?.filter((doc) => {
        const upload = uploadDocument?.[doc.document_type];
        return upload && upload.files.length > 0;
      }),
    [uploadDocument, warrantyDocument],
  );

  const missingDocs = useMemo(
    () =>
      warrantyDocument?.filter((doc) => {
        const upload = uploadedDocs.find(
          (upload) => doc.document_type === upload.document_type,
        );
        return !upload;
      }),
    [uploadedDocs, warrantyDocument],
  );

  const isFormValid = useMemo(
    () => missingDocs.filter((doc) => !!doc.required_flg).length === 0,
    [missingDocs],
  );

  return (
    <div className={style['container']}>
      <div className={style['warning-container']}>
        <p className={style['content']}>
          ご提出書類に不足及び不備がございました。
        </p>
        <div>
          <span>＜必要書類＞</span>
          <h4>・コンディションシート</h4>
          <h4>・車検証</h4>
        </div>
        <div>{data?.status_remark}</div>
      </div>
      <p className={style['content']}>
        申請をご希望されます場合は、大変お手数ではございますが、上記の書類（およびお写真）のアップロードをお願いいたします。
      </p>
      <div className={style['upload-group']}>
        <h3>未アップロードの書類</h3>
        <div className={style['upload-list']}>
          {missingDocs.length === 0 && <p>未アップロードの書類はありません</p>}
          {missingDocs.map((doc) => (
            <div
              key={doc.m_warranty_document_property_id}
              className={style['upload-item']}
              onClick={() => handleClickDocument(doc)}
            >
              <IconCheckGrey />
              <p>{doc.name}</p>
              {!!doc.required_flg && (
                <span className={style['required']}>必須</span>
              )}
              <Icon f7="chevron_right" size={24} />
            </div>
          ))}
        </div>
      </div>
      {uploadedDocs.length > 0 && (
        <div className={style['upload-group']}>
          <h3>アップロード済みの書類</h3>
          <div className={style['upload-list']}>
            {uploadedDocs.map((doc) => (
              <div
                key={doc.m_warranty_document_property_id}
                className={style['upload-item']}
                onClick={() => handleClickDocument(doc)}
              >
                <IconCheckGreen />
                <p>{doc.name}</p>
                {!!doc.required_flg && (
                  <span className={style['required']}>必須</span>
                )}
                <Icon f7="chevron_right" size={24} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={style['footer-button']}>
        <Button disabled={!isFormValid} onClick={onSubmit}>
          次へ
        </Button>
      </div>
    </div>
  );
};
