import { useState } from 'react';
import { ContactPopup } from '../Partials/ContactPopup/ContactPopup';
import { BankAccountTypeOptions } from '../Partials/validateSchema';
import style from './TradeGuaranteeRefundAccountStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { RefundAccount } from '@/types/api/warrantyApi';

interface TradeGuaranteeRefundAccountProps {
  refundAccount?: RefundAccount;
  hasRefundAccount?: number;
}

const LENGTH_NUMBER_SHOW = 4;

const renderAccountNumber = (accountNumber?: string) => {
  if (!accountNumber) return '';

  const str = accountNumber.replace(' ', '');
  const lastValue = str.slice(-LENGTH_NUMBER_SHOW);

  return lastValue.padStart(str.length, '*');
};

export const TradeGuaranteeRefundAccount = ({
  refundAccount,
  hasRefundAccount,
}: TradeGuaranteeRefundAccountProps) => {
  const [isContactPopupOpened, setIsContactPopupOpened] = useState(false);

  const dataRefundAccount = [
    { label: '銀行名', value: refundAccount?.bank_name },
    { label: '支店名', value: refundAccount?.branch_name },
    {
      label: '口座種別',
      value: BankAccountTypeOptions.find(
        (item) => item.value === refundAccount?.account_type,
      )?.text,
    },
    { label: '口座名義', value: refundAccount?.account_holder },
    {
      label: '口座番号',
      value: renderAccountNumber(refundAccount?.account_number),
    },
  ];

  return (
    <div className={style['trade-guarantee-receiving-account']}>
      <RoundSheet radius="16px" py="16px">
        <h4 className={style['header']}>この後の流れ</h4>
        <p className={style['content']}>
          ご納車時にお渡ししておりました書類一式をご用意の上、お車をご購入いただきました販売店へお越しくださいませ。
          <br />
          お手数ではございますが、事前に販売店へご一報いただき、営業時間内にご来店いただきますようお願い申し上げます。
          <br />
          【書類】
          <li>ご注文書（ご契約書）</li>
          <li>任意保険にご加入の場合、そのお控え等の書類​</li>
          <li>ローン契約がおありの場合、そのお控え等の書類​</li>
          <li>
            他、お車のご契約およびご納車に際して販売店からお渡しされた書類​
          </li>
        </p>

        <p className={style['trade-guarantee-result-note']}>
          ご不明なことがございましたら、
          <span onClick={() => setIsContactPopupOpened(true)}>
            サポートセンター
          </span>
          までお問い合わせくださいませ。
        </p>
        <ContactPopup
          opened={isContactPopupOpened}
          handleClose={() => setIsContactPopupOpened(false)}
        />
      </RoundSheet>
      {!!hasRefundAccount && (
        <>
          <div style={{ height: 24 }} />
          <RoundSheet radius="16px" py="16px">
            <h4 className={style['header']}>受け取り口座</h4>
            {dataRefundAccount.map((item, index) => (
              <div className={style['wrapper']} key={`info-bank-${index}`}>
                <div className={style['wrapper-label']}>{item.label}</div>
                <div className={style['wrapper-value']}>{item.value}</div>
              </div>
            ))}
          </RoundSheet>
        </>
      )}
    </div>
  );
};
