import { List, ListItem, f7 } from 'framework7-react';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { paths } from '@/config/paths';
import './popupStyle.scss';
type PopupUploadDocumentProps = {
  opened: boolean;
  onPopupClosed: () => void;
};

export const PopupUploadDocument: FC<PopupUploadDocumentProps> = ({
  opened,
  onPopupClosed,
}) => {
  const handleClickUpload = () => {
    f7.view.main.router.navigate(paths.tradeGuaranteeDocuments, {
      animate: true,
    });
    onPopupClosed();
  };
  return (
    <PopupTemplate
      id="TradeGuaranteePlanId"
      title=""
      opened={opened}
      isCloseIcon={false}
      onPopupClosed={onPopupClosed}
      closeByBackdropClick
    >
      <div className="wrapper">
        <div>
          <div className="bulb">
            <i className="icon-bulb" />
            下記書類をお手元にご準備ください
          </div>
          <List menuList mediaList>
            <ListItem mediaList>契約書</ListItem>
            <ListItem mediaList>車検証</ListItem>
            <ListItem mediaList>コンディションシート（お持ちの場合）</ListItem>
          </List>
        </div>
        <div className="bulb">
          <i className="icon-bulb" />
          車両の外装・内装写真の登録が必要となります
        </div>
        <div className="bulb">
          <i className="icon-bulb" />
          契約書記載の装備品・付属品写真の登録が必要となります
        </div>
        <div className="button2">
          {/* TODO */}
          <Button onClick={handleClickUpload}>書類をアップロードする</Button>
          <Button fill={false} onClick={onPopupClosed}>
            後から登録する
          </Button>
        </div>
      </div>
    </PopupTemplate>
  );
};
